// src/context/CartContext.js
import React, { createContext, useContext, useState,useEffect } from 'react';
import { getAuth, onAuthStateChanged,getIdToken } from 'firebase/auth';
const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartCount, setCartCount] = useState(0);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth,async (currentUser) => {
      if (currentUser) {
        const idToken = await getIdToken(currentUser);
        fetchCartCount(idToken);
      } else {
        setCartCount(0);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchCartCount = async (userId) => {
    // console.log(userId)
    try {
      setLoading(true);
      const response = await fetch(`https://www.campuscart.co/api/cart/count`,{
        headers:{
            "Authorization":`Bearer ${userId}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch cart count');
      const data = await response.json();
      console.log(data)
      setCartCount(data.count || 0);
    } catch (error) {
      console.error('Error fetching cart count:', error);
      setCartCount(0);
    } finally {
      setLoading(false);
    }
  };

  
  const updateCartCount = (count) => {
    setCartCount(count);
  };

  const incrementCartCount = () => {
    setCartCount(prev => prev + 1);
  };

  const decrementCartCount = () => {
    setCartCount(prev => Math.max(0, prev - 1));
  };

  return (
    <CartContext.Provider value={{ 
      cartCount, 
      updateCartCount,
      incrementCartCount,
      decrementCartCount 
    }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCartCount = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCartCount must be used within a CartProvider');
  }
  return context;
};