import './App.css';
import React, { Suspense, lazy } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import { signInWithPopup, GoogleAuthProvider, onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from './firebase';
import { CartProvider } from './context/CartContext';
// Lazy load components
const NavBar = lazy(() => import('./components/NavBar'));
const MobileNav = lazy(() => import('./components/MobileNav'));
const SimpleSlider = lazy(() => import('./components/HeroCarousel'));
const HeroSection = lazy(() => import('./components/HeroSection'));
const Ingridients = lazy(() => import('./components/Ingridients'));
const JournalSection = lazy(() => import('./components/JournalSection'));
const SinglePage = lazy(() => import('./components/SinglePage'));
const JournalPage = lazy(() => import('./components/JournalPage'));
const CartHold = lazy(() => import('./components/CartHold'));
const FollowONIG = lazy(() => import('./components/FollowONIG'));
const ContactUs = lazy(() => import('./components/ContactUs'));
const Products = lazy(() => import('./components/Products'));
const Footer = lazy(() => import('./components/SPFooter'));
const Under10 = lazy(() => import('./components/Under10'));
const ForHer = lazy(() => import('./components/ForHer'));
const ScrollToTop = lazy(() => import('./components/ScrollToTop'));
const AnimatedLoginSignup = lazy(() => import('./components/Login'));
const OrdersPage = lazy(() => import('./components/Orders'));

// Loading component to show during lazy loading
const LoadingFallback = () => (
  <div className="flex justify-center items-center h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);

const Login = () => {
  const navigate = useNavigate();

  const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(() => {
        navigate('/');  // Redirect to home page after successful login
      }).catch((error) => {
        console.error("Error during Google Sign-In:", error);
      });
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <button 
        onClick={handleGoogleSignIn}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Sign In with Google
      </button>
    </div>
  );
};

function App() {
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    }); 

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      console.error("Error signing out:", error);
    });
  };

  return (
    <div className="App">
      <CartProvider>
      <BrowserRouter>
        <Suspense fallback={<LoadingFallback />}>
          <Routes>
            <Route path='/' element={
              <>
                <NavBar user={user} onLogout={handleLogout} />
                <MobileNav />
                <SimpleSlider />
                <HeroSection />
                <Products />
                <Ingridients />
                <JournalSection />
                <FollowONIG />
                <Footer />
              </>
            } />
            <Route path='/:id' element={
              <>
                <NavBar user={user} onLogout={handleLogout} />
                <MobileNav />
                <SinglePage />
              </>
            } />
            <Route path='/journal/april' element={
              <>
                <NavBar user={user} onLogout={handleLogout} />
                <MobileNav />
                <JournalPage />
              </>
            } />
            <Route path='/cart' element={
             <>
                <NavBar user={user} onLogout={handleLogout} />
                <MobileNav />
                <CartHold />
                </>
            } />
            
            <Route path='/category/:type' element={
              <>
                <NavBar user={user} onLogout={handleLogout} />
                <MobileNav/>
                <Under10 />
              </>
            } />
            <Route path='/shop' element={
              <>
                <NavBar user={user} onLogout={handleLogout} />
                <MobileNav />
                <ForHer/>
              </>
            }/>
            <Route path='/forher' element={
              <>
                <NavBar user={user} onLogout={handleLogout} />
                <ForHer />
              </>
            } />
            
            <Route path='/login' element={<AnimatedLoginSignup />} />
            <Route path='/orders' element={<OrdersPage/>}/>
            <Route path='/contactus' element={<><NavBar/><MobileNav/><ContactUs/><Footer/></>}></Route>
          </Routes>
        </Suspense>
        <ScrollToTop />
      </BrowserRouter>
      </CartProvider>
    </div>
  );
}

export default App;