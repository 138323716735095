// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "@firebase/firestore";
import { getAuth } from "firebase/auth"; 
const firebaseConfig = {
  apiKey: "AIzaSyA2HXwz9QDwxfd3hhNwBVqEEbSasaWntdA",
  authDomain: "campuskart-1372e.firebaseapp.com",
  projectId: "campuskart-1372e",
  storageBucket: "campuskart-1372e.appspot.com",
  messagingSenderId: "54251815094", 
  appId: "1:54251815094:web:723cdcd6f413fbe4d52617",
  measurementId: "G-X1XJVM4FYG"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app); 

export const db= getFirestore(app)